<template>
  <div class="px-3">
    <div>
      <div>
      
        <div class="form-group mt-2 ">
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25 8.24999L2 4.99999L5.25 1.74999C5.575 1.42499 5.575 0.908319 5.25 0.583319C4.925 0.258319 4.40834 0.258319 4.08334 0.583319L0.258337 4.40832C-0.0666626 4.73332 -0.0666626 5.25832 0.258337 5.58332L4.08334 9.41665C4.40834 9.74165 4.925 9.74165 5.25 9.41665C5.575 9.09165 5.575 8.57499 5.25 8.24999ZM10.75 8.24999L14 4.99999L10.75 1.74999C10.425 1.42499 10.425 0.908319 10.75 0.583319C11.075 0.258319 11.5917 0.258319 11.9167 0.583319L15.7417 4.40832C16.0667 4.73332 16.0667 5.25832 15.7417 5.58332L11.9167 9.41665C11.5917 9.74165 11.075 9.74165 10.75 9.41665C10.425 9.09165 10.425 8.57499 10.75 8.24999Z" fill="#6D6B6D"/>
</svg>
<label for="content" class="ml-2 editorStyleTitle">Custom Code:</label>
          
      <CodeEditor spellcheck="false" :language_selector="false" height="324px" width="100%" :languages="[['html', 'HTML']]" id="HTMLText" v-model.trim="block.content" theme="light"  ></CodeEditor>
        </div>

       
      </div>
<div class="d-flex gap align-items-center">
  <div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"   v-model="block.style.height" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Rotate" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg
            width="18"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="currentColor"
            />
          </svg></span>
  <input type="number"   v-model="block.style.rotate" placeholder="Opacity" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Opacity" style="gap:4px">
<span class=""> 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z" fill="#B3AFB6"/>
</svg>
         </span>
  <input type="number"   v-model="block.style.textOpacity" placeholder="Opacity" class="editor-input-control w-100 input-bg-color border-0">
</div>
</div>
<div class="mt-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
    </div>
  </div>
</template>
<script>
import CodeEditor from 'simple-code-editor';
import Padding from '../MiniComponents/Padding.vue';
export default {
  components: {
    CodeEditor,
    Padding
  },
  props: {
    block: Object,
    index: Number,
    QuestionTitleExist:Boolean,
     isDesktopView:Boolean,
     isResultPage:Boolean,
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

    //   this.contentLocal = this.content;
  },
  methods:{
    isPropertyDisabled(property){
       if (this.block.content.toLowerCase().includes(property)) {
              return true;
            }
     return false
    },
    addProperty(property){
      this.block.content += " " + property
    }
   
  }
};
</script>
<style scoped>

.text-setting select
{
  font-size: 13px;
    padding: 0;
}

.PropertyDropdown :deep(.dropdown-toggle){
  background:none;
  color:#000
}
.PropertyDropdown :deep(.dropdown-item:active){
     color: #212529;
    background-color: transparent;
}
</style>
